<template>
  <div>
    <a-result
      :status="store.resultInfo.type"
      :title="store.resultInfo.title"
      :sub-title="store.resultInfo.subTitle"
    ></a-result>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
const route = useRoute();
const store = reactive({
  msg: "",
  resultInfo: {
    type: "info",
    title: "",
    subTitle: "",
  },
});
let code = route.query.code;
let state = route.query.state;
if (state == null || state == "" || code == "") {
  store.resultInfo.type = "error";
  store.resultInfo.title = "绑定失败";
  store.resultInfo.subTitle = "未获取到用户信息";
} else {
  let stateList = state.split("_");
  let appId = stateList[0];
  let userinfo = stateList[1];
  let serveUrl = decodeURIComponent(stateList[2]);

  store.msg = appId + userinfo + serveUrl;
  // 发送POST请求
  axios
    .post(
      // "http://test.api.rlinks.com.cn:8060/public/wechat/app-userinfo/bind",
      serveUrl + "/public/wechat/app-userinfo/bind",
      {
        appId: appId,
        userinfo: userinfo,
        code: code,
      }
    )
    .then(function (response) {
      console.log("response->", response);
      // store.msg = "绑定成功";
      store.resultInfo.type = "success";
      store.resultInfo.title = "绑定成功";
      store.resultInfo.subTitle = "请返回小程序";
    })
    .catch(function (error) {
      // console.log("error->", error);
      store.msg = error.response.data.msg;
      store.resultInfo.type = "error";
      store.resultInfo.title = "绑定失败";
      store.resultInfo.subTitle = "请重试或联系管理人员";
    });
}
</script>

<style></style>
