import { createApp } from "vue";
import App from "./App.vue";

const app = createApp(App); //创建VUE对象

//导入路由配置
import router from "./router/index.js";
app.use(router);

import Antd from "ant-design-vue";
app.use(Antd);

app.mount("#app");
