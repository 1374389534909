import { createRouter, createWebHistory } from "vue-router";

import Home from "../views/home.vue";

const routes = [
  {
    path: "",
    redirect: "home",
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: "首页",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
